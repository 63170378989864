import { graphql } from "gatsby";
import React from "react";
import Layout from "../../components/Layout";
import { GatsbyImage, getImage } from "gatsby-plugin-image";

function BlogPost({ data }) {
  console.log("data", data);
  const image = getImage(data.mdx.frontmatter.hero_image)
  return (
    <Layout>
      <h1>here is a post</h1>
      <GatsbyImage image={image}/>
    </Layout>
  );
}

export const query = graphql`
  query QuerySpecificPostBySlug($id: String) {
    mdx(id: {eq: $id}) {
      frontmatter {
        title
        hero_image {
          childImageSharp {
            gatsbyImageData(aspectRatio: 1.5
              transformOptions: {cropFocus: CENTER}
              backgroundColor: "#f333"
              avifOptions: {speed: 10, quality: 10}
              height: 500
              width: 900
            )
          }
        }
      }
      slug
    }
  }
`;

export default BlogPost;
